import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const router = new Router({
	routes: [
		{
			path: '/',
			redirect: 'school'
		},
		{
			path: '/',
			component: () => import('../components/Home.vue'),
			meta: { title: 'home' },
			children: [
				{
					path: 'school',
					component: () => import('../pages/school'),
					meta: { title: '校情分析' }
				},
				{
					path: 'grade',
					component: () => import('../pages/grade'),
					meta: { title: '年级期末成绩分析' }
				},
				{
					path: 'classroom',
					component: () => import('../pages/classroom'),
					meta: { title: '班级期末成绩分析' }
				},
				{
					path: 'student/:id',
					component: () => import('../pages/student'),
					props: true,
					meta: { title: '学生详情' }
				},
			]
		},

	]
})
export default router;